<template>
  <div class="stepper">
    <div class="stepper-head">
      <div class="stepper-head__inner">
        <div
          class="stepper-head__item"
          v-for="(step, key) in steps"
          v-html="step"
          :key="`stepper_head_${key}`"
        />

        <div
          class="stepper-head__progress"
          :style="`width: ${(activeStep / stepsCount) * 100}%;`"
        />
      </div>
    </div>
    <div class="stepper-body">
      <div v-for="(step, key, index) in steps" :key="`stepper_item_${key}`">
        <div
          class="stepper-body__item"
          v-if="index + 1 <= activeStep"
          v-show="activeStep === index + 1"
          :key="index"
        >
          <slot :name="key" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stepper',
  props: {
    steps: {
      type: Object,
      default: undefined,
    },
    activeStep: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    stepsCount() {
      if (this.steps) return Object.keys(this.steps).length;
      return 0;
    },
  },
};
</script>

<style scoped lang="scss">
$progress-height: 3px;

.stepper {
  width: 100%;
}
.stepper-head {
  border-radius: $base-border-radius;
  overflow: hidden;

  &__inner {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    box-sizing: border-box;
    counter-reset: stepper;
    background-color: $color-white;
    padding: 20px 16px;
    border-bottom: $progress-height solid $secondary-color;
  }

  &__item {
    flex-grow: 1;
    text-align: center;
    &::before {
      content: counter(stepper) '. ';
      counter-increment: stepper;
    }
  }

  &__progress {
    position: absolute;
    bottom: $progress-height * -1;
    left: 0;
    height: $progress-height;
    background: $color-primary-1-day;
    z-index: 3;
  }
}

.stepper-body__item {
  margin-top: 20px;
}
</style>
